//
//  sidemenu.js
//  SideMenu
//
//  Created by Tevon Wallace on 12/8/19.
//  Copyright © 2019 Tevon Wallace. All rights reserved.
//

import React from 'react'
import { headerLinks } from '../../constants/constants'
import { toDarkModeName } from '../../utils/utils'
import CloseIcon from '@material-ui/icons/Close'
import './sidemenu.scss'

const getClassName = (selectedIndex, currentIndex, mode) => {
    return selectedIndex === currentIndex ? `${toDarkModeName(mode, "sideMenuNames")} ${toDarkModeName(mode, "sideMenuProjects")} ${toDarkModeName(mode, "selectedSideMenu")}` : `${toDarkModeName(mode, "sideMenuNames")} ${toDarkModeName(mode, "sideMenuProjects")}`
}

const SideMenu = (props) => {
    return (
        <div className={"headerSideBar"}>
            <div onClick={() => { props.showSideBar() }}>
                <CloseIcon
                    className={toDarkModeName(props.mode, "closeMenuIcon")} />
            </div>

            <div style={{ marginTop: 37.5, marginLeft: 40 }}>
                {headerLinks.map((link, index) => (
                    <a href={link.link} style={index === headerLinks.length - 1 ? { marginBottom: 50 } : null}>
                        <div className={getClassName(props.selectedIndex, index, props.mode)}><div>{link.title}</div></div>
                    </a>
                ))}
            </div>
        </div>
    )
}

export default SideMenu
