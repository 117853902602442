import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import './simple_dialog.scss'
import { toDarkModeName } from '../../../utils/utils';
import { Input } from '../../input/input';

const SimpleDialog = (props) => {
    const { open, status, title, description, handleClose, mode } = props

    return (
        <Dialog
            open={open}
            onClose={handleClose}>
            <div
                className={toDarkModeName(mode, "simpleDialogBackground")}>
                <DialogTitle><div className={status ? toDarkModeName(mode, 'title') : 'error'}>{title}</div></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div className={toDarkModeName(mode, 'description')}>{description}</div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Input
                        field={{
                            elementConfig: {
                                type: 'button'
                            },
                            label: 'Done',
                            buttonWasPressed: handleClose
                        }}
                        mode={mode} />
                </DialogActions>
            </div>
        </Dialog>
    )
}

export default SimpleDialog
