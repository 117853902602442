//
//  index.js
//  index
//
//  Created by Tevon Wallace on 10/12/20.
//  Copyright © 2020 Tevon Wallace. All rights reserved.
//

import React from 'react'
import './index.scss'
import { TopComponent } from './top_component/topcomponent';
import { ImagePreviews } from './image_previews/imagepreviews';
import { toDarkModeName } from '../../utils/utils';
import { pages } from '../../constants/constants';
import history from '../../history/history'
import LoadingIndicator from '../../components/loading_indicator/loading_indicator';
import { getApps } from './apps/apps';

class AppPreview extends React.Component {
    state = {
        canProceed: false,
        appIndex: 0
    }

    componentDidMount = () => {
        this.updateProperties()
    }

    updateProperties = () => {
        if (this.props.app === pages[3]) {
            this.setState({ canProceed: true, appIndex: 0 })
        }
        else if (this.props.app === pages[6]) {
            this.setState({ canProceed: true, appIndex: 1 })
        }
        else if (this.props.app === pages[9]) {
            this.setState({ canProceed: true, appIndex: 2 })
        }
        else if (this.props.app === pages[13]) {
            this.setState({ canProceed: true, appIndex: 3 })
        }
        else {
            history.push(pages[0])
        }
    }

    render() {
        const { mode } = this.props
        const { canProceed, appIndex } = this.state
        const currentApp = getApps(this.props.mode)[appIndex]

        return (
            canProceed ?
                <div className={toDarkModeName(mode, "entireVisualFileEditorSection")}>
                    <TopComponent
                        image={currentApp.topComponent.image}
                        title={currentApp.topComponent.title}
                        supportLink={currentApp.topComponent.supportLink}
                        supportTitle={currentApp.topComponent.supportTitle}
                        link={currentApp.topComponent.link}
                        links={currentApp.topComponent.links}
                        privacy={currentApp.topComponent.privacy}
                        mode={mode}
                        additionalDescriptions={currentApp.topComponent.additionalDescriptions} />

                    <ImagePreviews
                        title={"iOS Previews"}
                        description={"The images displayed below are a representation of the app running on iOS devices."}
                        images={currentApp.iPhoneImages}
                        mode={mode} />

                    {currentApp.iPadImages.length > 0 ? <ImagePreviews
                        title={"iPadOS Previews"}
                        description={"The images displayed below are a representation of the app running on iPadOS devices."}
                        images={currentApp.iPadImages}
                        mode={mode} /> : null}

                    <div
                        style={{ marginBottom: 19, height: 1 }}>
                    </div>
                </div> :
                <LoadingIndicator />
        )
    }
}

export default AppPreview
