//
//  history.js
//  History
//
//  Created by Tevon Wallace on 12/8/19.
//  Copyright © 2020 Tevon Wallace. All rights reserved.
//

import { createBrowserHistory } from 'history'

export default createBrowserHistory()