//
//  contacts-form.js
//  Contacts Form
//
//  Created by Tevon Wallace on 07/08/21.
//  Copyright © 2021 Tevon Wallace. All rights reserved.
//

import React from 'react'
import { Grid } from '@material-ui/core';
import '../contacts.scss'
import { toDarkModeName } from '../../../utils/utils';
import { Input } from '../../../components/input/input';

export const ContactForm = (props) => {
    const { nameField, telephoneField, emailField, messageField, isSubmitDisabled, showLoadingIndicator, mode, submitButtonWasPressed, handleChange } = props

    return <div className={toDarkModeName(mode, 'contactsBackground')}>
        <div className={toDarkModeName(mode, 'contactForm')}>
            <Grid className={toDarkModeName(mode, 'optionBackground')} container direction='row' style={{ marginTop: 5 }} justify={'center'}>
                <Grid item xs={12}>
                    <div className={toDarkModeName(mode, 'largeTitle')} style={{ fontSize: 20 }}>Contact Form</div>
                </Grid>

                <Grid container direction={'row'}>
                    <Grid item xs={12} sm={6} className={toDarkModeName(mode, 'largeTitleBackground')}>
                        <Input
                            field={nameField}
                            width={'95%'}
                            mode={mode}
                            handleChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} sm={6} className={toDarkModeName(mode, 'largeTitleBackground')}>
                        <Input
                            field={telephoneField}
                            width={'95%'}
                            mode={mode}
                            handleChange={handleChange} />
                    </Grid>
                </Grid>

                <Grid container direction={'row'} style={{ marginTop: 5 }}>
                    <Grid item xs={12} sm={6} className={toDarkModeName(mode, 'largeTitleBackground')}>
                        <Input
                            field={emailField}
                            width={'95%'}
                            mode={mode}
                            handleChange={handleChange} />
                    </Grid>

                    <Grid item xs={12} sm={6} className={toDarkModeName(mode, 'largeTitleBackground')}>
                        <Input
                            field={messageField}
                            width={'95%'}
                            mode={mode}
                            handleChange={handleChange} />
                    </Grid>
                </Grid>

                <div
                    style={{ marginTop: 5, marginBottom: 5 }}>
                    <Input
                        field={{
                            elementConfig: {
                                type: 'button'
                            },
                            label: 'Submit',
                            isDisabled: isSubmitDisabled,
                            isLoading: showLoadingIndicator,
                            buttonWasPressed: submitButtonWasPressed
                        }}
                        mode={mode} />
                </div>
            </Grid>
        </div>
    </div>
}
