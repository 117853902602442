//
//  footer.js
//  Footer
//
//  Created by Tevon Wallace on 12/8/19.
//  Copyright © 2020 Tevon Wallace. All rights reserved.
//

import React from 'react'
import { Grid } from '@material-ui/core'
import './footer.scss'
import { toDarkModeName } from '../../utils/utils'
import { footerLinks } from '../../constants/constants'

const Footer = (props) => (
    <div style={{ marginTop: 0 }}>
        <Grid container className={toDarkModeName(props.mode, "footer")} direction={"row"} justify={'space-around'}>
            {footerLinks.map((link, index) => (
                <Grid item xs={3} key={'footerKey' + index}>
                    <div className={toDarkModeName(props.mode, "footerItems")}><a href={link.link} target={"_blank"} rel="noopener noreferrer">{link.title.toUpperCase()}</a></div>
                </Grid>
            ))}
        </Grid>
    </div>
)

export default Footer
