//
//  httprequests.js
//  HttpRequests
//
//  Created by Tevon Wallace on 12/8/19.
//  Copyright © 2020 Tevon Wallace. All rights reserved.
//

import { PRODUCTION_ENVIRONMENT, viewData } from '../constants/constants';
import { getDeviceDictionary, getPageName, toDatePath } from '../utils/utils';
import { ref, set, push, child } from 'firebase/database'

export const updateFirebasePageData = (pathName, database) => {
    if (process.env.NODE_ENV === PRODUCTION_ENVIRONMENT) {
        let pageName = getPageName(pathName)

        let date = new Date();

        let dictionary = getDeviceDictionary(date)

        var path = viewData + pageName + "/" + toDatePath(date);

        path = path + "/" + push(child(ref(database), path)).key;

        set(ref(database, path), dictionary).catch((_) => {
            console.log("error tracking analytics")
        })
    }
}
