//
//  sidemenuconstants.js
//  SideMenuConstants
//
//  Created by Tevon Wallace on 29/6/20.
//  Copyright © 2020 Tevon Wallace. All rights reserved.
//

export const getSideBarOpenStatus = () => {
    if (window.innerWidth >= 635) {
        return false
    }
}
