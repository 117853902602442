//
//  privacy.js
//  Privacy
//
//  Created by Tevon Wallace on 10/12/20.
//  Copyright © 2020 Tevon Wallace. All rights reserved.
//

import React from 'react'
import { Grid } from '@material-ui/core';
import './privacy.scss'
import { isChrome, toDarkModeName } from '../../../utils/utils';

export const Privacy = (props) => {
    const smSize = window.innerWidth > 1400 ? 3 : window.innerWidth > 826 ? 6 : 7
    const isChromeBrowser = isChrome()

    return <div className={toDarkModeName(props.mode, "privacy")}>
        <Grid container direction={"row"} justify={"center"}>
            <Grid item xs={12}>
                <div>
                    <div className={toDarkModeName(props.mode, "privacyTitle")}>{props.privacy.title}</div>
                    <div className={toDarkModeName(props.mode, "privacyDescription")}>{props.privacy.description}</div>
                </div>
            </Grid>

            <div className={"privacyList"}>
                <Grid container direction={"row"} justify={"space-evenly"}>
                    {props.privacy.privacyList.map((privacyItem, index) => (
                        <Grid item xs={6} sm={smSize} key={"privacy-" + index}>
                            <li className={toDarkModeName(props.mode, "listItem")}><div className={toDarkModeName(props.mode, "privacyItem")} style={isChromeBrowser ? { marginTop: -20 } : { marginTop: 0 }}>{privacyItem}</div></li>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </Grid>
    </div>
}
