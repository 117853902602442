//
//  errorview.js
//  ErrorView
//
//  Created by Tevon Wallace on 12/8/19.
//  Copyright © 2020 Tevon Wallace. All rights reserved.
//

import React from 'react'
import { Grid } from '@material-ui/core';
import { toDarkModeName } from '../../../utils/utils';
import "./errorview.scss"

const ErrorView = ({ mode }) => (
    <div className={"errorView"}>
        <Grid container justify={"center"}>
            <Grid item xs={12}>
                <div className={toDarkModeName(mode, "nameAndDescription")}>
                    <div className={toDarkModeName(mode, "name")} style={{ color: "#990000" }}>Something went wrong</div>
                    <div className={toDarkModeName(mode, "description")} style={{ color: "#990000", fontSize: 16 }}>An error occured. Please try again</div>
                    <div className={toDarkModeName(mode, "description")} style={{ color: "#990000", textAlign: "center", fontSize: 16, marginTop: 20 }}>or &nbsp;<u><a href="mailto:tevonwallace@tevonwallace.com" style={{ color: "#990000" }}>email</a></u>&nbsp;Tevon Wallace, Thanks</div>
                </div>
            </Grid>
        </Grid>
    </div>
)

export default ErrorView
