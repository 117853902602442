//
//  privacyoptions.js
//  Privacy Options
//
//  Created by Tevon Wallace on 11/12/20.
//  Copyright © 2020 Tevon Wallace. All rights reserved.
//

export const AppPrivacyPolicy = (title) => {
    return {
        title: "App Privacy",
        description: "All data collected by " + title + " is only for maintenance purposes. The different data collected are as follows:",
        privacyList: [
            "Performance",
            "Diagnostics",
            "Usage Data",
            "Crash Data"
        ]
    }
}
