import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress'
import './loading_indicator.scss'

const LoadingIndicator = () => {
    return (
        <div className={'circularProgress'}>
            <CircularProgress
                color={'primary'}
                size={100}
                style={{
                    color: 'white'
                }} />
        </div>
    )
}

export default LoadingIndicator
