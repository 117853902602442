//
//  projectitem.js
//  ProjectItem
//
//  Created by Tevon Wallace on 19/8/19.
//  Copyright © 2020 Tevon Wallace. All rights reserved.
//

import React from 'react'
import './projectitem.scss'
import { ProjectSection } from './projectsection'

export const ProjectItem = (props) => (
    <div>
        {props.project.link && !props.project.websiteLink ?
            <a className={"projectSectionLink"} href={props.project.link[0].link} target={"_blank"} rel="noopener noreferrer">
                <ProjectSection project={props.project} mode={props.mode} style={props.style} />
            </a> :
            props.project.websiteLink ?
                <ProjectSection project={props.project} mode={props.mode} style={props.style} href={props.project.websiteLink.link} />
                :
                <ProjectSection project={props.project} mode={props.mode} style={props.style} />}
    </div>
)
