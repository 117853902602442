//
//  rydesafeoptions.js
//  ryde_safe Options
//
//  Created by Tevon Wallace on 30/09/22.
//  Copyright © 2022 Tevon Wallace. All rights reserved.
//

// import darkModeFirstiPhoneImage from '../../../../assets/images/projects/apps/ryde_safe/iPhone/dark_mode/1.png'
import lightModeFirstiPhoneImage from '../../../../assets/images/projects/apps/ryde_safe/iPhone/light_mode/1.png'
// import darkModeSecondiPhoneImage from '../../../../assets/images/projects/apps/ryde_safe/iPhone/dark_mode/2.png'
import lightModeSecondiPhoneImage from '../../../../assets/images/projects/apps/ryde_safe/iPhone/light_mode/2.png'
// import darkModeThirdiPhoneImage from '../../../../assets/images/projects/apps/ryde_safe/iPhone/dark_mode/3.png'
import lightModeThirdiPhoneImage from '../../../../assets/images/projects/apps/ryde_safe/iPhone/light_mode/3.png'
// import darkModeFourthiPhoneImage from '../../../../assets/images/projects/apps/ryde_safe/iPhone/dark_mode/4.png'
import lightModeFourthiPhoneImage from '../../../../assets/images/projects/apps/ryde_safe/iPhone/light_mode/4.png'
// import darkModeFifthiPhoneImage from '../../../../assets/images/projects/apps/ryde_safe/iPhone/dark_mode/5.png'
import lightModeFifthiPhoneImage from '../../../../assets/images/projects/apps/ryde_safe/iPhone/light_mode/5.png'
// import darkModeSixthiPhoneImage from '../../../../assets/images/projects/apps/ryde_safe/iPhone/dark_mode/6.png'
import lightModeSixthiPhoneImage from '../../../../assets/images/projects/apps/ryde_safe/iPhone/light_mode/6.png'

export const getRydeSafeOptions = (mode) => {
    // const isDarkMode = mode === "dark"

    return {
        iPhoneImages: [
            {
                title: null,
                image: lightModeFirstiPhoneImage /* isDarkMode ? darkModeFirstiPhoneImage : lightModeFirstiPhoneImage*/
            },
            {
                title: null,
                image: lightModeSecondiPhoneImage /* isDarkMode ? darkModeSecondiPhoneImage : lightModeSecondiPhoneImage */
            },
            {
                title: null,
                image: lightModeThirdiPhoneImage /* isDarkMode ? darkModeThirdiPhoneImage : lightModeThirdiPhoneImage */
            },
            {
                title: null,
                image: lightModeFourthiPhoneImage /* isDarkMode ? darkModeFourthiPhoneImage : lightModeFourthiPhoneImage */
            },
            {
                title: null,
                image: lightModeFifthiPhoneImage /* isDarkMode ? darkModeFifthiPhoneImage : lightModeFifthiPhoneImage */
            },
            {
                title: null,
                image: lightModeSixthiPhoneImage /* isDarkMode ? darkModeSixthiPhoneImage : lightModeSixthiPhoneImage */
            }
        ],
        iPadImages: [],
        additionalDescriptions: [
            "Create your account as passenger or driver and book a ride within minutes.",
            "View recent offers sent from drivers.",
            "View close by drivers when booking a ride.",
            "View suggested price for new rides.",
            "Drivers, accept trips or bid at a more convenient price.",
            "Manage your payment methods as you wish. Card payments are processed using Stripe.",
            "Receive push and in app notifications for every activity.",
            "In the event of an emergency, quickly trigger an emergency alert to notify your contacts.",
            "Manage your profile.",
        ]
    }
}
