//
//  qualifications.js
//  Qualifications
//
//  Created by Tevon Wallace on 16/8/19.
//  Copyright © 2020 Tevon Wallace. All rights reserved.
//

import React from 'react'
import { Grid } from '@material-ui/core';
import './qualifications.scss'
import { toDarkModeName } from '../../../utils/utils';

export const Qualifications = (props) => (
    <div
        className={"qualifications"}>
        <Grid container className={toDarkModeName(props.mode, "qualificationsSection")}>
            <Grid item xs={12}>
                <div className={toDarkModeName(props.mode, "qualificationsTitle")}>{props.data.title.toUpperCase()}</div>
            </Grid>
            <Grid container direction={props.data.secondList !== undefined && props.data.secondList !== null ? "row" : "column"} justify={"space-between"} className={toDarkModeName(props.mode, "qualicationsSubSection")}>
                <Grid item xs={props.data.secondList === undefined ? 12 : window.innerWidth > 927 ? 6 : 12}>
                    <ul className={"qualificationList"}>
                        {props.data.value.map((value, index) => (
                            <div key={index} className={"qualificationsItems"}>
                                <li className={`${toDarkModeName(props.mode, "qualificationsItemTitle")}${value.isCurrent ? " selected" : ""}`}>{value.title}</li>

                                {props.data.file ?
                                    <div style={{ marginTop: 5, marginBottom: -20 }}>
                                        <div className={"downloadFile"}>
                                            <div className={`${toDarkModeName(props.mode, "qualificationsItem")}${value.isCurrent ? " selected" : ""}`}>
                                                <a href={props.data.file.file} target={"_blank"} rel="noopener noreferrer"><div className={`${toDarkModeName(props.mode, "qualificationsItem a")}`}>
                                                    {props.data.file.title}</div>
                                                </a>
                                            </div>
                                        </div>
                                    </div> :
                                    <ul>
                                        {value.levels.map((levelValue, levelIndex) => (
                                            <div>
                                                {value.downloadLink && levelIndex === value.levels.length - 1 ? <a href={value.downloadLink} target={"_blank"} rel="noopener noreferrer">
                                                    <div className={`${toDarkModeName(props.mode, "qualificationsItem")}${value.isCurrent ? " selected" : ""}`}>
                                                        <li key={"level" + levelIndex} className={`${toDarkModeName(props.mode, "qualificationsItem a")}`}>{levelValue}</li>
                                                    </div></a> :
                                                    <li key={"level" + levelIndex} className={`${toDarkModeName(props.mode, "qualificationsItem")}${value.isCurrent ? " selected" : ""}`}>{levelValue}</li>}
                                            </div>
                                        ))}

                                        {value.major ?
                                            <ul>
                                                <li className={`${toDarkModeName(props.mode, props.isQualifications ? "qualificationsItem qualificationsItemNoWrap" : "qualificationsItem")}${value.isCurrent ? " selected" : ""}`}>{value.major}</li>
                                            </ul>
                                            : null}
                                    </ul>}
                            </div>
                        ))}
                    </ul>
                </Grid>
                {props.data.secondList !== undefined && props.data.secondList !== null ?
                    <Grid item xs={window.innerWidth > 927 ? 6 : 12} style={{ marginTop: window.innerWidth > 927 ? 0 : -20 }}>
                        <ul className={"qualificationList"}>
                            {props.data.secondList.map((value, index) => (
                                <div key={index} className={"qualificationsItems"}>
                                    <li className={toDarkModeName(props.mode, "qualificationsItemTitle")}>{value.title}</li>
                                </div>
                            ))}
                        </ul>
                    </Grid> : null}
            </Grid>
        </Grid>
    </div>
)
