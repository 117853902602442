//
//  errorboundary.js
//  ErrorBoundary
//
//  Created by Tevon Wallace on 12/8/19.
//  Copyright © 2020 Tevon Wallace. All rights reserved.
//

import React from 'react'
import Layout from '../../components/layout/layout';
import ErrorView from './error_view/errorview'

class ErrorBoundary extends React.Component {
  state = {
    hasError: false
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  render() {
    const { isSideBarOpen, showSideBar, selectedIndex, mode, children, changeViewMode } = this.props
    const { hasError } = this.state

    return <Layout
      isSideBarOpen={isSideBarOpen}
      showSideBar={showSideBar}
      selectedIndex={selectedIndex}
      mode={mode}
      changeViewMode={changeViewMode}>
      {hasError ?
        <ErrorView
          mode={mode} /> :
        children}
    </Layout>
  }
}

export default ErrorBoundary
