import React from "react"
import PropTypes from "prop-types"
import Button from "@material-ui/core/Button"
import { TailSpin } from "react-loader-spinner"
import css from "./css.module.scss"
import colors from "../../assets/colors/colors.scss"

const ReusableButton = (props) => {
    const { onClick, disabled, isLoading, label, padding, mode } = props

    const stylePadding = padding ? padding : { paddingLeft: 58, paddingRight: 58, paddingTop: 14, paddingBottom: 14 }
    const disabledStyles = { backgroundColor: mode === 'dark' ? colors.headerBackgroundColor : colors.greyColor, boxShadow: 'none', ...stylePadding, height: 47, ...props.style }

    const primaryStyles = { border: '1px solid ' + (mode === 'dark' ? 'white' : colors.headerBackgroundColor), ...stylePadding, height: 47, ...props.style }

    return (
        <Button
            variant={'outlined'}
            onClick={onClick}
            disabled={isLoading ? true : disabled}
            style={isLoading ? disabledStyles : disabled === true ? disabledStyles : primaryStyles} >

            {isLoading ?
                <div style={{ marginRight: 6, marginTop: 4 }}>
                    <TailSpin
                        color={"#00BFFF"}
                        height={16}
                        width={16} />
                </div> : null}
            <span className={disabled === true ? css.disabledClass : css.primaryClass}> {label}</span>
        </Button>
    )
}

ReusableButton.propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool
}

ReusableButton.defaultProps = {
    label: 'Button',
    onClick: null,
    disabled: false
};

export default ReusableButton
