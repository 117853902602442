//
//  supportform.js
//  SupportForm
//
//  Created by Tevon Wallace on 10/12/20.
//  Copyright © 2020 Tevon Wallace. All rights reserved.
//

import React from 'react'
import { Grid } from '@material-ui/core';
import '../index.scss'
import { toDarkModeName } from '../../../../utils/utils';
import { Input } from './../../../../components/input/input'

export const SupportForm = (props) => {
    const { submitButtonWasPressed, title, emailField, messageField, appVersion, platform, handleChange, isLoading, isSubmitDisabled, mode } = props

    return <form autoComplete="on" onSubmit={submitButtonWasPressed} className={toDarkModeName(mode, "supportForm")}>
        <Grid container direction={"row"}>
            <Grid item xs={12}>
                <div className={"formFields"}>
                    <div className={toDarkModeName(mode, "formTitle")}>{title}</div>
                    <div className={toDarkModeName(mode, "formDescription")}>Please complete the form below.</div>
                </div>
            </Grid>

            <Grid container direction={"row"} justify={"space-evenly"}>
                <Grid item xs={12} sm={6}>
                    <div className={"formFields"}>
                        <Input
                            field={emailField}
                            mode={mode}
                            width={'100%'}
                            handleChange={handleChange} />
                    </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div className={"formFields"}>
                        <Input
                            field={messageField}
                            mode={mode}
                            width={'100%'}
                            handleChange={handleChange} />
                    </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div className={"formFields"}>
                        <Input
                            field={appVersion}
                            mode={mode}
                            width={'100%'}
                            handleChange={handleChange} />
                    </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <div className={"formFields"} justify={"space-between"}>
                        <Input
                            field={platform}
                            mode={mode}
                            width={'100%'}
                            handleChange={handleChange} />
                    </div>
                </Grid>
            </Grid>

            <Input
                field={{
                    elementConfig: {
                        type: 'button'
                    },
                    label: 'Submit',
                    isDisabled: isSubmitDisabled,
                    isLoading: isLoading,
                    buttonWasPressed: submitButtonWasPressed
                }}
                mode={mode} />
        </Grid>
    </form>
}
