//
//  store.js
//  Store
//
//  Created by Tevon Wallace on 12/8/19.
//  Copyright © 2020 Tevon Wallace. All rights reserved.
//

import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import browserHistory from '../history/history'
import logger from 'redux-logger';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import reducers from '../reducers/reducers';
import { routerMiddleware } from 'react-router-redux';
import { PRODUCTION_ENVIRONMENT } from '../constants/constants';

function configureReduxStoreForProduction(state) {
    const reactRouterMiddleware = routerMiddleware(browserHistory)

    const middlewares = [thunk, reactRouterMiddleware]

    return createStore(reducers, state, compose(applyMiddleware(...middlewares)))
}

function configureReduxStoreForDevelopment(state) {
    const reactRouterMiddleware = routerMiddleware(browserHistory)

    const middlewares = [reduxImmutableStateInvariant(), thunk, reactRouterMiddleware, logger]

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

    const reduxStore = createStore(reducers, state, composeEnhancers(applyMiddleware(...middlewares)))

    return reduxStore
}

export default process.env.NODE_ENV === PRODUCTION_ENVIRONMENT ? configureReduxStoreForProduction({}) : configureReduxStoreForDevelopment({})
