//
//  App.js
//  App
//
//  Created by Tevon Wallace on 11/8/19.
//  Copyright © 2020 Tevon Wallace. All rights reserved.
//

import React from 'react';
import { Provider } from 'react-redux';
import AppRoutes from './routes/routes';
import history from './history/history'
import ErrorBoundary from './pages/error_boundary/errorboundary';
import { pages, home } from './constants/constants';
import './app.css';
import LoadingIndicator from './components/loading_indicator/loading_indicator'
import { getSideBarOpenStatus } from './constants/sidemenuconstants';
import { containsValidLink, getPageIndex, updatePageTitle } from './utils/utils';
import { updateFirebasePageData } from './networking/httprequests';
import { withFirebase } from './networking/firebase';
import { compose } from 'recompose';

class BaseApp extends React.Component {
  state = {
    pages: pages,
    mode: window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light',
    canProceed: true,
    isSideBarOpen: false,
  }

  componentDidMount = () => {
    const { mode } = this.state
    const pathName = this.props.history.location.pathname

    const pathUrl = containsValidLink(pathName)

    if (pathUrl !== null) {
      updateFirebasePageData(pathName, this.props.firebase.database)

      window.location = pathUrl
    }
    else if (!this.state.pages.includes(pathName)) {
      history.push(home)
    }
    else {
      updateFirebasePageData(pathName, this.props.firebase.database)

      updatePageTitle(pathName)
    }

    const localStorageMode = localStorage.getItem("mode")

    if (localStorageMode === null) {
      this.performUpdatesAfterModeSwitch(mode)
    }
    else {
      this.modeAction(localStorageMode)
    }

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => this.colorSchemeWasChanged(event.matches ? 'dark' : 'light'))
    window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', () => { })

    window.addEventListener('resize', this.windowWasResized)
  }

  colorSchemeWasChanged = (mode) => {
    this.modeAction(mode, () => {
      localStorage.removeItem("mode")
    })
  }

  modeAction = (mode, callBack) => {
    this.setState({ mode: mode }, () => {
      this.performUpdatesAfterModeSwitch(mode)

      if (callBack === undefined) {
        localStorage.setItem("mode", mode)
      }
      else {
        callBack()
      }
    })
  }

  performUpdatesAfterModeSwitch = (mode) => {
    if (mode === 'dark') {
      document.body.classList.add('dark-mode')
    }
    else {
      document.body.classList.remove('dark-mode')
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.windowWasResized)
  }

  windowWasResized = () => {
    this.setState({ isSideBarOpen: getSideBarOpenStatus() })
  }

  showSideBar = () => {
    let isSideBarOpen = !this.state.isSideBarOpen

    if (isSideBarOpen) {
      document.body.style.overflow = "hidden";
    }
    else {
      document.body.style.overflow = "scroll";
    }

    this.setState({ isSideBarOpen: isSideBarOpen })
  }

  changeViewMode = () => {
    this.modeAction(this.state.mode === "dark" ? "light" : "dark")
  }

  render() {
    const { store, history } = this.props
    const { mode, canProceed, isSideBarOpen } = this.state
    const { showSideBar, changeViewMode } = this

    return (
      canProceed ?
        <ErrorBoundary
          isSideBarOpen={isSideBarOpen}
          showSideBar={showSideBar}
          selectedIndex={getPageIndex(history.location.pathname)}
          mode={mode}
          changeViewMode={changeViewMode}>
          <Provider store={store} >
            <AppRoutes
              history={history}
              mode={mode} />
          </Provider>
        </ErrorBoundary>
        : <LoadingIndicator />
    )
  }
}

const App = compose(
  withFirebase
)(BaseApp)

export default App
