//
//  visualfileeditoroptions.js
//  VisualFileEditorOptions
//
//  Created by Tevon Wallace on 10/12/20.
//  Copyright © 2020 Tevon Wallace. All rights reserved.
//

import darkModeFirstiPhoneImage from '../../../../assets/images/projects/apps/visual_file_editor/iPhone/dark_mode/1.png'
import lightModeFirstiPhoneImage from '../../../../assets/images/projects/apps/visual_file_editor/iPhone/light_mode/1.png'
import darkModeSecondiPhoneImage from '../../../../assets/images/projects/apps/visual_file_editor/iPhone/dark_mode/2.png'
import lightModeSecondiPhoneImage from '../../../../assets/images/projects/apps/visual_file_editor/iPhone/light_mode/2.png'
import darkModeThirdiPhoneImage from '../../../../assets/images/projects/apps/visual_file_editor/iPhone/dark_mode/3.png'
import lightModeThirdiPhoneImage from '../../../../assets/images/projects/apps/visual_file_editor/iPhone/light_mode/3.png'
import darkModeFourthiPhoneImage from '../../../../assets/images/projects/apps/visual_file_editor/iPhone/dark_mode/4.png'
import lightModeFourthiPhoneImage from '../../../../assets/images/projects/apps/visual_file_editor/iPhone/light_mode/4.png'
import darkModeFifthiPhoneImage from '../../../../assets/images/projects/apps/visual_file_editor/iPhone/dark_mode/5.png'
import lightModeFifthiPhoneImage from '../../../../assets/images/projects/apps/visual_file_editor/iPhone/light_mode/5.png'
import darkModeSixthiPhoneImage from '../../../../assets/images/projects/apps/visual_file_editor/iPhone/dark_mode/6.png'
import lightModeSixthiPhoneImage from '../../../../assets/images/projects/apps/visual_file_editor/iPhone/light_mode/6.png'

import darkModeFirstiPadImage from '../../../../assets/images/projects/apps/visual_file_editor/iPad/dark_mode/1.png'
import lightModeFirstiPadImage from '../../../../assets/images/projects/apps/visual_file_editor/iPad/light_mode/1.png'
import darkModeSecondiPadImage from '../../../../assets/images/projects/apps/visual_file_editor/iPad/dark_mode/2.png'
import lightModeSecondiPadImage from '../../../../assets/images/projects/apps/visual_file_editor/iPad/light_mode/2.png'
import darkModeThirdiPadImage from '../../../../assets/images/projects/apps/visual_file_editor/iPad/dark_mode/3.png'
import lightModeThirdiPadImage from '../../../../assets/images/projects/apps/visual_file_editor/iPad/light_mode/3.png'
import darkModeFourthiPadImage from '../../../../assets/images/projects/apps/visual_file_editor/iPad/dark_mode/4.png'
import lightModeFourthiPadImage from '../../../../assets/images/projects/apps/visual_file_editor/iPad/light_mode/4.png'

export const getVisualFileEditorOptions = (mode) => {
    const isDarkMode = mode === "dark"

    return {
        iPhoneImages: [
            {
                title: "Year or Month Filters",
                image: isDarkMode ? darkModeFirstiPhoneImage : lightModeFirstiPhoneImage
            },
            {
                title: "Imported/Created Documents",
                image: isDarkMode ? darkModeSecondiPhoneImage : lightModeSecondiPhoneImage
            },
            {
                title: "Settings",
                image: isDarkMode ? darkModeThirdiPhoneImage : lightModeThirdiPhoneImage
            },
            {
                title: "Previewing a Document",
                image: isDarkMode ? darkModeFourthiPhoneImage : lightModeFourthiPhoneImage
            },
            {
                title: "Editing or Viewing Documents",
                image: isDarkMode ? darkModeFifthiPhoneImage : lightModeFifthiPhoneImage
            },
            {
                title: "Quick Actions",
                image: isDarkMode ? darkModeSixthiPhoneImage : lightModeSixthiPhoneImage
            }
        ],
        iPadImages: [
            {
                title: "Imported/Created Documents",
                image: isDarkMode ? darkModeFirstiPadImage : lightModeFirstiPadImage
            },
            {
                title: "Previewing a Document",
                image: isDarkMode ? darkModeSecondiPadImage : lightModeSecondiPadImage
            },
            {
                title: "Saving a New File",
                image: isDarkMode ? darkModeThirdiPadImage : lightModeThirdiPadImage
            },
            {
                title: "Keyboard Commands",
                image: isDarkMode ? darkModeFourthiPadImage : lightModeFourthiPadImage
            }
        ],
        additionalDescriptions: [
            "Edit files on iOS and iPadOS with efficiency. Smooth and clean user interface.",
            "Select either App Processing or Regular Expressions to process your files.",
            "Advanced Search features using file name, file type, added date and modified date.",
            "View and edit the contents for a variety of source code file types.",
            "View the contents of PDFs.",
            "Search the contents of any opened document.",
            "Import files using either Visual File Editor or the Share Sheet from iOS or iPadOS in external locations.",
            "Create new files within Visual File Editor.",
            "Share modified files to external locations.",
            "Optimized for iPadOS with Smart/Magic Keyboard.",
            "Perform actions faster using the keyboard shortcuts in iPadOS."
        ]
    }
}
