//
//  header.js
//  Header
//
//  Created by Tevon Wallace on 12/8/19.
//  Copyright © 2020 Tevon Wallace. All rights reserved.
//

import React from 'react'
import { Grid } from '@material-ui/core'
import SideMenu from '../side_menu/sidemenu'
import tevonWallace from '../../assets/images/tevon_wallace/tevonwallace.png'
import { headerLinks } from '../../constants/constants.js'
import './header.scss'
import { toDarkModeName } from '../../utils/utils'
import MenuIcon from '@material-ui/icons/Menu'

const leftMargin = (width) => {
    return {
        marginLeft:
            width > 1400 ? '60%'
                : width > 1300 ? '55%'
                    : width > 1200 ? '50%'
                        : width > 800 ? '40%'
                            : width > 700 ? '35%'
                                : width < 700 ? '25%'
                                    : '25%'
    }
}

const getClassName = (selectedIndex, currentIndex, mode) => {
    return selectedIndex === currentIndex ? `headerNames ${toDarkModeName(mode, "headerItems")} selectedHeaderItem` : `headerNames ${toDarkModeName(mode, "headerItems")}`
}

const Header = (props) => (
    <Grid container className={toDarkModeName(props.mode, "headerBackground")} direction="row" justify="space-between">
        <Grid item xs={2} sm={1}>
            <div className={`headerNames ${toDarkModeName(props.mode, "headerName")}`}>
                <div onClick={props.changeViewMode}><img src={tevonWallace} alt={""} className={"tevonWallaceImage"} /></div>
                <a href="/" className={"tevonWallaceName"}>Tevon Wallace</a>
            </div>
        </Grid>
        <Grid container direction="row">
            {props.isSideBarOpen === true ?
                <SideMenu
                    selectedIndex={props.selectedIndex}
                    mode={props.mode}
                    showSideBar={props.showSideBar} /> :
                <Grid container className={'allRightItems'} direction={'row'} justify={'space-evenly'} style={leftMargin(props.width)} spacing={5}>
                    {headerLinks.map((link, index) => (
                        <Grid key={'headerKey' + index} item xs={2} className={"headerRightItems"} style={index === 0 ? { marginLeft: 80, marginRight: -30 } : index === headerLinks.length - 1 ? { marginRight: 20 } : null}>
                            <div className={getClassName(props.selectedIndex, index, props.mode)}>
                                <a href={link.link}>{link.title.toUpperCase()}</a>
                            </div>
                        </Grid>
                    ))}
                </Grid>}
            <Grid item xs={2} className={toDarkModeName(props.mode, "headerMenuIcon")}>
                <div onClick={() => { props.showSideBar() }}>
                    <MenuIcon
                        className={toDarkModeName(props.mode, "headerMenuIcon")} />
                </div>
            </Grid>
        </Grid>
    </Grid>
)

Header.propTypes = {
};

Header.defaultProps = {
    width: window.innerWidth
};

export default Header
