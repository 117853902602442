//
//  index.js
//  Index
//
//  Created by Tevon Wallace on 11/8/19.
//  Copyright © 2020 Tevon Wallace. All rights reserved.
//

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import App from './app';
import browserHistory from './history/history';
import configureReduxStore from './store/store';
import Firebase, { FirebaseContext } from './networking/firebase';
import ReactGA from 'react-ga';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_TRACKING_ID)
ReactGA.pageview(window.location.pathname)

ReactDOM.render(
    <FirebaseContext.Provider value={new Firebase()}>
        <App store={configureReduxStore} history={browserHistory} />
    </FirebaseContext.Provider>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
