//
//  layout.js
//  Layout
//
//  Created by Tevon Wallace on 16/08/21.
//  Copyright © 2021 Tevon Wallace. All rights reserved.
//

import React from 'react'
import { Grid } from '@material-ui/core'
import Header from '../header/header'
import Footer from '../footer/footer'

const Layout = ({ isSideBarOpen, showSideBar, selectedIndex, mode, changeViewMode, children }) => (
    <Grid container direction={'row'}>
        <Grid item>
            <Header
                isSideBarOpen={isSideBarOpen}
                showSideBar={showSideBar}
                width={window.innerWidth}
                selectedIndex={selectedIndex}
                mode={mode}
                changeViewMode={changeViewMode} />
        </Grid>

        <Grid item>
            {children}
        </Grid>

        <Grid item>
            <Footer
                mode={mode} />
        </Grid>
    </Grid>
)

export default Layout
