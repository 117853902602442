//
//  index.js
//  Index
//
//  Created by Tevon Wallace on 24/08/21.
//  Copyright © 2021 Tevon Wallace. All rights reserved.
//

import FirebaseContext, { withFirebase } from './context';
import Firebase from './firebase';

export default Firebase;

export { FirebaseContext, withFirebase };