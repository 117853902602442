//
//  routes.js
//  Routes
//
//  Created by Tevon Wallace on 12/8/19.
//  Copyright © 2020 Tevon Wallace. All rights reserved.
//

import React from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import Resume from '../pages/resume/resume';
import Projects from '../pages/projects/projects';
import Contacts from '../pages/contacts/contacts';
import AppPreview from '../pages/app_preview'
import AppSupport from '../pages/app_preview/support'
import { resume, contacts, home, appPreviewRoutes, appPreviewSupportRoutes } from '../constants/constants';

export const AppRoutes = (props) => (
    <BrowserRouter>
        <Routes>
            <Route path={home} element={<Projects mode={props.mode} />} ></Route>
            {appPreviewRoutes.map((path, _) =>
                <Route path={path} element={<AppPreview mode={props.mode} app={props.history.location.pathname} />}></Route>
            )}
            {appPreviewSupportRoutes.map((path, _) =>
                <Route path={path} element={<AppSupport mode={props.mode} app={props.history.location.pathname} />}></Route>
            )}
            <Route path={resume} element={<Resume mode={props.mode} />}></Route>
            <Route path={contacts} element={<Contacts mode={props.mode} />}></Route>
        </Routes>
    </BrowserRouter>
)

export default AppRoutes
