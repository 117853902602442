//
//  contacts.js
//  Contacts
//
//  Created by Tevon Wallace on 12/8/19.
//  Copyright © 2020 Tevon Wallace. All rights reserved.
//

import React from 'react'
import { Grid } from '@material-ui/core'
import './contacts.scss'
import { toDarkModeName, updateObject } from '../../utils/utils.js'
import { checkValidity } from '../../utils/checkValidity'
import SimpleDialog from '../../components/dialog/simple_dialog/simple_dialog.js'
import HttpClient from '../../networking/httpclient';
import { ContactForm } from './contact_form/contact_form';

class Contacts extends React.Component {
    state = {
        nameField: {
            id: "nameField",
            formElement: "nameField",
            label: "Name",
            className: null,
            errorMessage: null,
            validation: {
                isNotEmpty: true
            },
            elementConfig: {
                type: 'text',
            },
            isValid: false,
            value: ""
        },
        telephoneField: {
            id: "standard-basic",
            formElement: "telephoneField",
            label: "Telephone Number",
            className: null,
            errorMessage: null,
            validation: {
                isNotEmpty: true,
                isPhoneNumber: true
            },
            elementConfig: {
                type: 'text',
            },
            isValid: false,
            value: ""
        },
        emailField: {
            id: "standard-basic",
            formElement: "emailField",
            label: "Email Address",
            className: null,
            errorMessage: null,
            validation: {
                isNotEmpty: true,
                isEmail: true
            },
            elementConfig: {
                type: 'text',
            },
            isValid: false,
            value: ""
        },
        messageField: {
            id: "messageField",
            formElement: "messageField",
            label: "Enter message",
            className: null,
            errorMessage: null,
            validation: {
                isNotEmpty: true
            },
            elementConfig: {
                type: 'text',
            },
            isValid: false,
            value: ""
        },
        isSubmitDisabled: true,
        showLoadingIndicator: false,
        dialog: {
            open: false,
            status: false,
            title: "",
            description: ""
        }
    }

    handleChange = (formElement) => async (event) => {
        const validity = checkValidity(event.target.value, this.state[formElement].validation)

        const updatedControls = updateObject(this.state[formElement], {
            value: event.target.value,
            errorMessage: validity[1],
            isValid: validity[0],
        })

        this.setState({ [formElement]: updatedControls }, () => {
            const canSubmit = this.state.nameField.isValid && this.state.telephoneField.isValid && this.state.emailField.isValid && this.state.messageField.isValid

            this.setState({ isSubmitDisabled: !canSubmit })
        })
    }

    submitButtonWasPressed = (event) => {
        event.preventDefault()

        const { nameField, telephoneField, emailField, messageField } = this.state

        const body = {
            name: nameField.value,
            telephone: telephoneField.value,
            email: emailField.value,
            message: messageField.value
        }

        this.submitToBackend(body)
    }

    submitToBackend = (body) => {
        this.setState({ showLoadingIndicator: true, isSubmitDisabled: true })

        new HttpClient().submitContactForm(body).then((data) => {
            data.json().then((value) => {
                if (value.status === 'success') {
                    this.actionsAfterProcessingRequest({
                        status: true,
                        title: 'Submitted Successfully',
                        description: 'You will be contacted shortly. Thank you.'
                    })
                }
                else {
                    this.actionsAfterProcessingRequest()
                }
            }).catch((_) => {
                this.actionsAfterProcessingRequest()
            })
        }).catch((_) => {
            this.actionsAfterProcessingRequest()
        })
    }

    actionsAfterProcessingRequest = (data) => {
        const { dialog } = this.state

        dialog.open = true
        dialog.status = data ? data.status : false
        dialog.title = data ? data.title : 'Error When Submitting'
        dialog.description = data ? data.description : 'An error occured when submitting the form. Please try again.'

        this.setState({ showLoadingIndicator: false, isSubmitDisabled: false, dialog }, () => {
            if (data) {
                this.resetForm()
            }
        })
    }

    resetForm = () => {
        const { nameField, telephoneField, emailField, messageField } = this.state

        nameField.isValid = false
        nameField.value = ""
        nameField.errorMessage = null

        telephoneField.isValid = false
        telephoneField.value = ""
        telephoneField.errorMessage = null

        emailField.isValid = false
        emailField.value = ""
        emailField.errorMessage = null

        messageField.isValid = false
        messageField.value = ""
        messageField.errorMessage = null

        this.setState({ nameField, telephoneField, emailField, messageField, isSubmitDisabled: true })
    }

    closeModal = () => {
        const { dialog } = this.state

        dialog.open = false

        this.setState({ dialog })
    }

    render() {
        const { mode } = this.props
        const { nameField, telephoneField, emailField, messageField, isSubmitDisabled, dialog, showLoadingIndicator } = this.state
        const { submitButtonWasPressed, handleChange, closeModal } = this

        return (
            <div className={"contactPageBackground"}>
                <Grid container>
                    <Grid item xs={12}>
                        <div className={toDarkModeName(mode, "nameAndDescription")}>
                            <div className={toDarkModeName(mode, "name")}>Let's Connect</div>
                            <div className={toDarkModeName(mode, "description")}>Please complete the form below to submit a message to Tevon Wallace.</div>
                        </div>
                    </Grid>
                </Grid>

                <Grid container direction='row'>
                    <Grid item xs={12} style={{ marginTop: 20 }}
                        className={toDarkModeName(mode, "contactFormParent")}>
                        <ContactForm
                            nameField={nameField}
                            telephoneField={telephoneField}
                            emailField={emailField}
                            messageField={messageField}
                            isSubmitDisabled={isSubmitDisabled}
                            showLoadingIndicator={showLoadingIndicator}
                            mode={mode}
                            submitButtonWasPressed={submitButtonWasPressed}
                            handleChange={handleChange} />
                    </Grid>

                    <SimpleDialog
                        open={dialog.open}
                        status={dialog.status}
                        handleClose={closeModal}
                        title={dialog.title}
                        description={dialog.description}
                        mode={mode} />
                </Grid>
            </div>
        )
    }
}

export default Contacts
