//
//  context.js
//  Context
//
//  Created by Tevon Wallace on 24/08/21.
//  Copyright © 2021 Tevon Wallace. All rights reserved.
//

import React from 'react';

const FirebaseContext = React.createContext(null);

export const withFirebase = Component => props => (
    <FirebaseContext.Consumer>
        {firebase => <Component {...props} firebase={firebase} />}
    </FirebaseContext.Consumer>
)

export default FirebaseContext;
