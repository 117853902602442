//
//  utils.js
//  Utils
//
//  Created by Tevon Wallace on 10/8/19.
//  Copyright © 2020 Tevon Wallace. All rights reserved.
//

import {
    apps, contacts, contactsPage, heartBeatRemixPath, home, pages, appNames, projectsPage, resume, resumePage, support,
    visualFileEditorFaviconLink, visualFileEditorPage, restAPIClientFaviconLink, restAPIClientPage, visualFileEditorAppStoreLink,
    restAPIClientAppStoreLink, heartBeatRemixLink, listOfApps, rydeSafeAppStoreLink, rydeSafeFaviconLink, rydeSafePage, fifteenSec1AnsPage,
    fifteenSec1AnsFaviconLink, fifteenSec1AnsWebsiteLink, websiteName
} from "../constants/constants";

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties,
    };
}

export const updateFavicon = (url) => {
    document.getElementById("favicon").href = url
}

export const checkIfOptionsContain = (options, value) => {
    for (var index = 0; index < options.length; index++) {
        if (options[index].value === value) {
            return true;
        }
    }

    return false
}

export const toDarkModeName = (mode, name) => {
    return mode === 'dark' ? `darkMode${name.charAt(0).toUpperCase()}${name.substring(1)}` : name
}

export const goToLink = (link) => {
    window.open(link, link.charAt(0) === '/' ? "_self" : "_blank")
}

export const getDeviceDictionary = (date) => {
    const dateDictionary = {
        date: (date.getDate() < 10 ? "0" : "") + date.getDate() + "/" + ((date.getMonth() + 1) < 10 ? "0" : "") + (date.getMonth() + 1) + "/" + date.getFullYear(),
        time: (date.getHours() < 10 ? "0" : "") + date.getHours() + ":" + (date.getMinutes() < 10 ? "0" : "") + date.getMinutes() + ":" + date.getMilliseconds(),
        string: date.toString(),
    }

    var navigatorDictionary = {
        appCodeName: navigator.appCodeName,
        appName: navigator.appName,
        cookieEnabled: navigator.cookieEnabled,
        location: navigator.geolocation,
        vendor: navigator.vendor,
        webDriver: navigator.webdriver === undefined ? "" : navigator.webdriver,
        userAgent: navigator.userAgent,
        product: navigator.product,
        platform: navigator.platform,
        javaEnabled: navigator.javaEnabled()
    };

    if ((navigator.languages[0] !== null) && (navigator.languages[0] !== undefined)) {
        navigatorDictionary["language"] = navigator.languages[0]
    }

    const screenDictionary = {
        height: window.innerHeight,
        width: window.innerWidth,
        orientation: Math.abs(window.orientation) - 90 === 0 ? "landscape" : "portrait",
        mode: window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
    }

    const dictionary = {
        date: dateDictionary,
        navigator: navigatorDictionary,
        screen: screenDictionary
    }

    return dictionary
}

export const getPageIndex = (pathName) => {
    switch (pathName) {
        case home:
            return 0
        /*case resume:
            return 1
        case contacts:
            return 2*/
        case contacts:
            return 1
        default:
            return null
    }
}

export const getPageName = (pathName) => {
    switch (pathName) {
        case home:
            return projectsPage
        case resume:
            return resumePage
        case contacts:
            return contactsPage
        case pages[3]:
            return `${listOfApps[0].app}/body`
        case pages[4]:
            return listOfApps[0].support
        case pages[5]:
            return listOfApps[0].downloadLink
        case pages[6]:
            return `${listOfApps[1].app}/body`
        case pages[7]:
            return listOfApps[1].support
        case pages[8]:
            return listOfApps[1].downloadLink
        case pages[9]:
            return `${listOfApps[2].app}/body`
        case pages[10]:
            return listOfApps[2].support
        case pages[11]:
            return listOfApps[2].downloadLink
        case pages[13]:
            return `${listOfApps[3].app}/body`
        case pages[14]:
            return listOfApps[3].support
        case pages[15]:
            return listOfApps[3].downloadLink
        default:
            return pathName
    }
}

export const updatePageTitle = (pathName) => {
    var canAppendWebsiteName = true;

    switch (pathName) {
        case home:
            canAppendWebsiteName = false;
            document.title = websiteName
            break
        case resume:
            document.title = "Resumé"
            break
        case contacts:
            document.title = "Contacts"
            break
        case pages[3]:
            document.title = visualFileEditorPage
            updateFavicon(visualFileEditorFaviconLink)
            break
        case pages[4]:
            document.title = visualFileEditorPage + " - Support"
            updateFavicon(visualFileEditorFaviconLink)
            break
        case pages[6]:
            document.title = restAPIClientPage
            updateFavicon(restAPIClientFaviconLink)
            break
        case pages[7]:
            document.title = restAPIClientPage + " - Support"
            updateFavicon(restAPIClientFaviconLink)
            break
        case pages[9]:
            document.title = rydeSafePage
            updateFavicon(rydeSafeFaviconLink)
            break
        case pages[10]:
            document.title = rydeSafePage + " - Support"
            updateFavicon(rydeSafeFaviconLink)
            break
        case pages[13]:
            document.title = fifteenSec1AnsPage
            updateFavicon(fifteenSec1AnsFaviconLink)
            break
        case pages[14]:
            document.title = fifteenSec1AnsPage + " - Support"
            updateFavicon(fifteenSec1AnsFaviconLink)
            break
        default:
            canAppendWebsiteName = false;
            document.title = websiteName
            break
    }

    if (canAppendWebsiteName) {
        document.title += " - " + websiteName
    }
}

export const containsValidLink = (pathName) => {
    if (pathName === pages[5]) { // Visual File Editor
        return visualFileEditorAppStoreLink
    }
    else if (pathName === pages[8]) { // REST API Client
        return restAPIClientAppStoreLink
    }
    else if (pathName === pages[11]) { // Ryde Safe
        return rydeSafeAppStoreLink
    }
    else if (pathName === pages[15]) { // 15 Sec 1 Ans
        return fifteenSec1AnsWebsiteLink
    }
    else if (pathName === heartBeatRemixPath) {
        return heartBeatRemixLink
    }
    else {
        return null
    }
}

export const getPathAndProjectName = (pathName) => {
    if (pathName === pages[3]) {
        return [apps, appNames[0]]
    }
    else if (pathName === pages[4]) {
        return [support, appNames[0]]
    }
    else if (pages.includes(pathName)) {
        return [pathName, pathName]
    }
    else {
        return [pages[0], pages[0]]
    }
}

export const toFullMonth = (month) => {
    switch (month) {
        case "Jan":
            return "January"
        case "Feb":
            return "February"
        case "Mar":
            return "March"
        case "Apr":
            return "April"
        case "May":
            return "May"
        case "Jun":
            return "June"
        case "Jul":
            return "July"
        case "Aug":
            return "August"
        case "Sep":
            return "September"
        case "Oct":
            return "October"
        case "Nov":
            return "November"
        case "Dec":
            return "December"
        default:
            return month
    }
}

export const toFullMonthNumber = (month) => {
    switch (month) {
        case "Jan":
            return "01"
        case "Feb":
            return "02"
        case "Mar":
            return "03"
        case "Apr":
            return "04"
        case "May":
            return "05"
        case "Jun":
            return "06"
        case "Jul":
            return "07"
        case "Aug":
            return "08"
        case "Sep":
            return "09"
        case "Oct":
            return "10"
        case "Nov":
            return "11"
        case "Dec":
            return "12"
        default:
            return month
    }
}

export const toDatePath = (currentDate) => {
    const currentDateArray = new Date(currentDate).toDateString().split(" ")
    const newDateString = `${currentDateArray[3]}/${toFullMonthNumber(currentDateArray[1])}/${currentDateArray[2]}`

    return newDateString
}

export const isChrome = () => {
    return !!window.chrome
}