//
//  15sec1ansoptions.js
//  15Sec1Ans Options
//
//  Created by Tevon Wallace on 02/03/23.
//  Copyright © 2023 Tevon Wallace. All rights reserved.
//

import lightModeFirstiPhoneImage from '../../../../assets/images/projects/apps/15_sec_1_ans/iPhone/light_mode/1.png'
import lightModeSecondiPhoneImage from '../../../../assets/images/projects/apps/15_sec_1_ans/iPhone/light_mode/2.png'
import lightModeThirdiPhoneImage from '../../../../assets/images/projects/apps/15_sec_1_ans/iPhone/light_mode/3.png'

export const get15Sec1AnsOptions = (mode) => {
    return {
        iPhoneImages: [
            {
                title: null,
                image: lightModeFirstiPhoneImage /* isDarkMode ? darkModeFirstiPhoneImage : lightModeFirstiPhoneImage*/
            },
            {
                title: null,
                image: lightModeSecondiPhoneImage /* isDarkMode ? darkModeSecondiPhoneImage : lightModeSecondiPhoneImage */
            },
            {
                title: null,
                image: lightModeThirdiPhoneImage /* isDarkMode ? darkModeThirdiPhoneImage : lightModeThirdiPhoneImage */
            }
        ],
        iPadImages: [],
        additionalDescriptions: [
            "Receive short and fun audio clips.",
            "Submit your answer to increase your rank and points.",
            "Receive voice of the day audio clips for additional points.",
            "Test your skills daily!",
            "Test your skills in trivia!",
            "Increase your ranking!",
        ]
    }
}
