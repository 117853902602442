//
//  imagepreviews.js
//  ImagePreviews
//
//  Created by Tevon Wallace on 11/12/20.
//  Copyright © 2020 Tevon Wallace. All rights reserved.
//

import React from 'react'
import { Grid } from '@material-ui/core';
import './imagepreviews.scss'
import { toDarkModeName } from '../../../utils/utils'
// import { fadeInDown, bounce } from 'react-animations'
// import Radium, { StyleRoot } from 'radium';

// const animationStyles = {
//     fadeInDown: {
//         animation: 'x 1s',
//         animationName: Radium.keyframes(fadeInDown, 'fadeInDown')
//     },
//     bounce: {
//         animation: 'x 1s',
//         animationName: Radium.keyframes(bounce, 'bounce')
//     }
// }

export const ImagePreviews = (props) => {
    return <div className={toDarkModeName(props.mode, "imagePreviews")}>
        <Grid container justify={"space-evenly"}>
            <Grid item xs={12} sm={1}>
                <div className={toDarkModeName(props.mode, "imagePreviewTitle")}>{props.title}</div>
            </Grid>
            <Grid item xs={12}>
                <div className={toDarkModeName(props.mode, "imagePreviewDescription")}>{props.description}</div>
            </Grid>
            <Grid container style={{ marginTop: 50 }} justify={"space-evenly"}>
                {props.images.map((data, index) => (
                    // <Grid item key={"images" + index} xs={12} sm={props.title === "iOS Previews" ? 4 : 6} className={toDarkModeName(props.mode, "visualFileEditorImages")}>
                    //     <div className={toDarkModeName(props.mode, "visualFileEditorImageTitle")}>{data.title}</div>
                    //     <img className={toDarkModeName(props.mode, "visualFileEditorActualImage")} src={data.image} style={{ width: '90%' }} alt={"Not Found!"} />
                    // </Grid>

                    <Grid item key={"images" + index} xs={12} sm={props.title === "iOS Previews" ? 4 : 6} className={toDarkModeName(props.mode, "visualFileEditorImages")}>
                        <Grid container direction={'row'} justify={'center'} className={props.title === "iOS Previews" ? "iOSPreviews" : "iPadOSPreviews" /*{ marginLeft: '0.75%' }: { marginLeft: '1.5%' }} */}>
                            <Grid item xs={12}>
                                <div className={toDarkModeName(props.mode, "visualFileEditorImageTitle")}>{data.title}</div>
                            </Grid>
                            <Grid item xs={12}>
                                {/* <StyleRoot> */}
                                <img className={toDarkModeName(props.mode, "visualFileEditorActualImage")} src={data.image} style={props.title === "iOS Previews" ? { width: '98%' /*, ...animationStyles.fadeInDown */ } : { width: '98%' /*, ...animationStyles.fadeInDown*/ }} alt={"Not Found!"} />
                                {/* </StyleRoot> */}
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    </div>
}
