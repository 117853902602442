//
//  support.js
//  Support
//
//  Created by Tevon Wallace on 10/12/20.
//  Copyright © 2020 Tevon Wallace. All rights reserved.
//

import React from 'react'
import { pages } from '../../../constants/constants'
import { TopComponent } from '../top_component/topcomponent';
import { toDarkModeName, updateObject } from '../../../utils/utils';
import { checkValidity } from '../../../utils/checkValidity'
import '../index.scss'
import './index.scss'
import { SupportForm } from './support_form/supportform';
import HttpClient from '../../../networking/httpclient';
import SimpleDialog from '../../../components/dialog/simple_dialog/simple_dialog'
import { getApps } from '../apps/apps';
import LoadingIndicator from '../../../components/loading_indicator/loading_indicator'
import { getAppVersions } from '../apps/appversions';
import history from '../../../history/history'

class AppSupport extends React.Component {
    state = {
        currentApp: getApps(this.props.mode)[0],
        canProceed: false,
        dialog: {
            open: false,
            status: false,
            title: "",
            description: ""
        },
        emailField: {
            id: "email-field",
            formElement: "emailField",
            label: "Email Address",
            className: null,
            errorMessage: null,
            validation: {
                isEmail: true
            },
            elementConfig: {
                type: 'text',
            },
            isValid: false,
            value: ""
        },
        messageField: {
            id: "text-field",
            formElement: "messageField",
            label: "Support Message",
            className: null,
            errorMessage: null,
            validation: {
                isNotEmpty: true
            },
            elementConfig: {
                type: 'text',
            },
            isValid: false,
            value: ""
        },
        appVersion: {
            id: null,
            formElement: "appVersion",
            label: "Version",
            className: null,
            errorMessage: null,
            validation: {
                isNotEmpty: true
            },
            elementConfig: {
                type: 'select',
                options: getAppVersions(this.props.app).versions
            },
            isValid: true,
            value: getAppVersions(this.props.app).default
        },
        platform: {
            id: null,
            formElement: "platform",
            className: null,
            label: "Platform",
            errorMessage: null,
            validation: {
                isNotEmpty: true
            },
            elementConfig: {
                type: 'select',
                options: this.props.app === pages[10] ? [
                    { title: "iOS", value: "iOS" },
                    { title: "Android", value: "Android" }
                ] : [
                    { title: "iOS", value: "iOS" },
                    { title: "iPadOS", value: "iPadOS" }
                ]
            },
            isValid: true,
            value: "iOS"
        },
        isSubmitDisabled: true,
        showLoadingIndicator: false,
        showCaptcha: false
    }

    componentDidMount = () => {
        if (this.props.app === pages[4]) {
            this.setState({ canProceed: true })
        }
        else if (this.props.app === pages[7]) {
            this.setState({ currentApp: getApps(this.props.mode)[1], canProceed: true })
        }
        else if (this.props.app === pages[10]) {
            this.setState({ currentApp: getApps(this.props.mode)[2], canProceed: true })
        }
        else if (this.props.app === pages[14]) {
            this.setState({ currentApp: getApps(this.props.mode)[3], canProceed: true })
        }
        else {
            history.push(pages[0])
        }
    }

    handleChange = (formElement) => async (event) => {
        const validity = checkValidity(event.target.value, this.state[formElement].validation)

        const updatedControls = updateObject(this.state[formElement], {
            value: event.target.value,
            errorMessage: validity[1],
            isValid: validity[0],
        })

        this.setState({ [formElement]: updatedControls }, () => {
            const canSubmit = this.state.emailField.isValid && this.state.messageField.isValid && this.state.appVersion.isValid && this.state.platform.isValid

            this.setState({ isSubmitDisabled: !canSubmit })
        });
    }

    submitButtonWasPressed = (event) => {
        event.preventDefault();

        if (!this.state.emailField.isValid) {
            const updatedControls = updateObject(this.state.emailField, {
                errorMessage: "Please enter a valid email address",
            });

            this.setState({ emailField: updatedControls });
        }
        else if (!this.state.messageField.isValid) {
            const updatedControls = updateObject(this.state.messageField, {
                errorMessage: "Please enter a valid message",
            });

            this.setState({ messageField: updatedControls });
        }
        else if (!this.state.appVersion.isValid) {
            const updatedControls = updateObject(this.state.appVersion, {
                errorMessage: "Please select an app version",
            });

            this.setState({ appVersion: updatedControls });
        }
        else if (!this.state.platform.isValid) {
            const updatedControls = updateObject(this.state.platform, {
                errorMessage: "Please select a platform",
            });

            this.setState({ platform: updatedControls });
        }
        else {
            this.setState({ showLoadingIndicator: true, isSubmitDisabled: true })

            const body = {
                page: this.state.currentApp.supportFormPage,
                formData: {
                    emailAddress: this.state.emailField.value,
                    supportMessage: this.state.messageField.value,
                    appVersion: this.state.appVersion.value,
                    platform: this.state.platform.value
                }
            }

            new HttpClient().submitAppSupportForm(body).then((data) => {
                data.json().then((value) => {
                    if (value.status === 'success') {
                        this.uploadSupportFormCallBack(null)
                    }
                    else {
                        this.uploadSupportFormCallBack("error")
                    }
                }).catch((_) => {
                    this.uploadSupportFormCallBack("error")
                })
            }).catch((_) => {
                this.uploadSupportFormCallBack("error")
            })
        }
    }

    showCaptcha = () => {
        this.setState({ showCaptcha: true })
    }

    captchaCallBack = (token) => {
        console.log("Token: ", token)
    }

    uploadSupportFormCallBack = (error) => {
        if (error === null) {
            const { emailField, messageField, appVersion, platform, dialog } = this.state

            emailField.value = ""
            emailField.errorMessage = null
            emailField.isValid = false

            messageField.value = ""
            messageField.errorMessage = null
            messageField.isValid = false

            appVersion.value = getAppVersions(this.props.app).default
            appVersion.errorMessage = null
            appVersion.isValid = true

            platform.value = "iOS"
            platform.errorMessage = null
            platform.isValid = false

            dialog.open = true
            dialog.status = true
            dialog.title = "Submitted Successfully"
            dialog.description = "The support form was submitted successfully. You will receive an update shortly about your request. Thank you."

            this.setState({ emailField, messageField, appVersion, platform, dialog, showLoadingIndicator: false, isSubmitDisabled: true });
        }
        else {
            this.setState({
                dialog: updateObject(this.state.dialog, {
                    ...this.state.dialog,
                    open: true,
                    title: "Error When Submitting Form",
                    description: "An error occurred when submitting the form. Please try again."
                }), showLoadingIndicator: false, isSubmitDisabled: false
            });
        }
    }

    closeAlertDialog = () => {
        this.setState({
            dialog: updateObject(this.state.dialog, {
                ...this.state.dialog,
                open: false
            })
        })
    }

    render() {
        const { mode } = this.props
        const { canProceed, currentApp, dialog, emailField, messageField, appVersion, platform, showLoadingIndicator, isSubmitDisabled } = this.state
        const { closeAlertDialog, submitButtonWasPressed, handleChange } = this

        return (
            canProceed ?
                <div className={toDarkModeName(mode, "entireVisualFileEditorSection")}>
                    <TopComponent
                        image={currentApp.support.roundedImage}
                        title={currentApp.support.title}
                        supportLink={currentApp.support.supportLink}
                        supportTitle={currentApp.support.supportTitle}
                        link={currentApp.support.link}
                        links={currentApp.support.links}
                        mode={mode}
                        additionalDescriptions={currentApp.support.additionalDescriptions}
                        privacy={currentApp.support.privacy} />

                    <SimpleDialog
                        open={dialog.open}
                        status={dialog.status}
                        handleClose={closeAlertDialog}
                        title={dialog.title}
                        description={dialog.description}
                        mode={mode} />

                    <div
                        className={toDarkModeName(mode, "supportFormParent")}>
                        <SupportForm
                            title={currentApp.supportFormPage}
                            emailField={emailField}
                            messageField={messageField}
                            appVersion={appVersion}
                            platform={platform}
                            submitButtonWasPressed={submitButtonWasPressed}
                            handleChange={handleChange}
                            isLoading={showLoadingIndicator}
                            isSubmitDisabled={isSubmitDisabled}
                            mode={mode} />
                    </div>
                </div> :
                <LoadingIndicator />
        )
    }
}

export default AppSupport
