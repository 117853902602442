//
//  restapiclientoptions.js
//  RESTClientEditor Options
//
//  Created by Tevon Wallace on 06/09/21.
//  Copyright © 2021 Tevon Wallace. All rights reserved.
//

import darkModeFirstiPhoneImage from '../../../../assets/images/projects/apps/rest_api_client/iPhone/dark_mode/1.png'
import lightModeFirstiPhoneImage from '../../../../assets/images/projects/apps/rest_api_client/iPhone/light_mode/1.png'
import darkModeSecondiPhoneImage from '../../../../assets/images/projects/apps/rest_api_client/iPhone/dark_mode/2.png'
import lightModeSecondiPhoneImage from '../../../../assets/images/projects/apps/rest_api_client/iPhone/light_mode/2.png'
import darkModeThirdiPhoneImage from '../../../../assets/images/projects/apps/rest_api_client/iPhone/dark_mode/3.png'
import lightModeThirdiPhoneImage from '../../../../assets/images/projects/apps/rest_api_client/iPhone/light_mode/3.png'
import darkModeFourthiPhoneImage from '../../../../assets/images/projects/apps/rest_api_client/iPhone/dark_mode/4.png'
import lightModeFourthiPhoneImage from '../../../../assets/images/projects/apps/rest_api_client/iPhone/light_mode/4.png'
import darkModeFifthiPhoneImage from '../../../../assets/images/projects/apps/rest_api_client/iPhone/dark_mode/5.png'
import lightModeFifthiPhoneImage from '../../../../assets/images/projects/apps/rest_api_client/iPhone/light_mode/5.png'
import darkModeSixthiPhoneImage from '../../../../assets/images/projects/apps/rest_api_client/iPhone/dark_mode/6.png'
import lightModeSixthiPhoneImage from '../../../../assets/images/projects/apps/rest_api_client/iPhone/light_mode/6.png'

import darkModeFirstiPadImage from '../../../../assets/images/projects/apps/rest_api_client/iPad/dark_mode/1.png'
import lightModeFirstiPadImage from '../../../../assets/images/projects/apps/rest_api_client/iPad/light_mode/1.png'
import darkModeSecondiPadImage from '../../../../assets/images/projects/apps/rest_api_client/iPad/dark_mode/2.png'
import lightModeSecondiPadImage from '../../../../assets/images/projects/apps/rest_api_client/iPad/light_mode/2.png'
import darkModeThirdiPadImage from '../../../../assets/images/projects/apps/rest_api_client/iPad/dark_mode/3.png'
import lightModeThirdiPadImage from '../../../../assets/images/projects/apps/rest_api_client/iPad/light_mode/3.png'
import darkModeFourthiPadImage from '../../../../assets/images/projects/apps/rest_api_client/iPad/dark_mode/4.png'
import lightModeFourthiPadImage from '../../../../assets/images/projects/apps/rest_api_client/iPad/light_mode/4.png'

export const getRestClientOptions = (mode) => {
    const isDarkMode = mode === "dark"

    return {
        iPhoneImages: [
            {
                title: "Year or Month Filters",
                image: isDarkMode ? darkModeFirstiPhoneImage : lightModeFirstiPhoneImage
            },
            {
                title: "Created Requests",
                image: isDarkMode ? darkModeSecondiPhoneImage : lightModeSecondiPhoneImage
            },
            {
                title: "Settings",
                image: isDarkMode ? darkModeThirdiPhoneImage : lightModeThirdiPhoneImage
            },
            {
                title: "Request Response",
                image: isDarkMode ? darkModeFourthiPhoneImage : lightModeFourthiPhoneImage
            },
            {
                title: "Response Headers",
                image: isDarkMode ? darkModeFifthiPhoneImage : lightModeFifthiPhoneImage
            },
            {
                title: "Authorization Type",
                image: isDarkMode ? darkModeSixthiPhoneImage : lightModeSixthiPhoneImage
            }
        ],
        iPadImages: [
            {
                title: "Created Requests",
                image: isDarkMode ? darkModeFirstiPadImage : lightModeFirstiPadImage
            },
            {
                title: "Selecting Requests",
                image: isDarkMode ? darkModeSecondiPadImage : lightModeSecondiPadImage
            },
            {
                title: "Response Headers",
                image: isDarkMode ? darkModeThirdiPadImage : lightModeThirdiPadImage
            },
            {
                title: "Keyboard Commands",
                image: isDarkMode ? darkModeFourthiPadImage : lightModeFourthiPadImage
            }
        ],
        additionalDescriptions: [
            "Perform REST and GraphQL network requests, view response headers and results.",
            "Add query parameters, authorization, headers or body to any request.",
            "Select from the 4 body parameters [none, form-data, form-urlencoded, raw] and add it to any request.",
            "Perform search features using the request name, request url, http method or the modified date of a request.",
            "Filter requests by months or years.",
            "Create, save, update or delete any request within REST API Client.",
            "Optimized for iPadOS with Smart/Magic Keyboard.",
            "Perform actions faster using the keyboard shortcuts in iPadOS."
        ]
    }
}
