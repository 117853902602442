//
//  topcomponent.js
//  TopComponent
//
//  Created by Tevon Wallace on 10/12/20.
//  Copyright © 2020 Tevon Wallace. All rights reserved.
//

import React from 'react'
import { Grid } from '@material-ui/core';
import './topcomponent.scss'
import { toDarkModeName } from '../../../utils/utils';
import { AppStoreLinks } from '../app_store_links/appstorelinks';
import { Privacy } from '../privacy/privacy';
import { fadeIn } from 'react-animations'
import Radium, { StyleRoot } from 'radium';
import { rydeSafePage } from '../../../constants/constants'

const supportTitleClassName = (supportTitle) => {
    return supportTitle === "Support" ? "supportLink" : supportTitle === rydeSafePage ? "inBetweenSupportLink" : "longSupportLink"
}

const animationStyles = {
    fadeIn: {
        animation: 'x 1s',
        animationName: Radium.keyframes(fadeIn, 'fadeIn')
    }
}

export const TopComponent = (props) => {
    return <Grid container>
        <Grid container direction={"row"} justify={"space-evenly"} className={toDarkModeName(props.mode, "topComponent")}>
            <Grid item xs={12} sm={5}>
                <StyleRoot>
                    {props.link ? <a href={props.link.link} target={"_blank"} rel="noopener noreferrer"><img className={"topComponentImage"} style={animationStyles.fadeIn} src={props.image} alt={"Not Found!"} /></a> : <img className={"topComponentImage"} src={props.image} alt={"Not Found!"} />}
                </StyleRoot>
                <a href={props.supportLink}><div className={supportTitleClassName(props.supportTitle)}>{props.supportTitle}</div></a>
            </Grid>

            <Grid item xs={12} sm={5}>
                <div className={toDarkModeName(props.mode, "projectNameAndDescription")}>
                    <div className={props.title === "Visual File Editor" ? toDarkModeName(props.mode, "projectName") : toDarkModeName(props.mode, "supportProjectName")}>{props.title}</div>

                    <ul className={toDarkModeName(props.mode, "descriptionUnOrderedList")}>
                        <Grid container direction={"row"} justify={"space-evenly"}>
                            {props.additionalDescriptions.map((description, index) => (
                                <Grid item xs={12} key={"topComponent-" + index}>
                                    <li><div className={toDarkModeName(props.mode, "additionalDescriptions")}>{description}</div></li>
                                </Grid>
                            ))}
                        </Grid>
                    </ul>
                </div>
            </Grid>
        </Grid>

        <Grid container direction={'row'} justify={'space-evenly'} style={props.mode === 'dark' ? { marginTop: 10 } : null}>
            <Grid item xs={12} sm={5}>
                <AppStoreLinks
                    stores={props.links}
                    mode={props.mode}
                    support={{
                        link: props.supportLink,
                        title: props.supportTitle
                    }} />
            </Grid>
            <Grid item xs={12} sm={5}>
                <Privacy
                    privacy={props.privacy}
                    mode={props.mode} />
            </Grid>
        </Grid>
    </Grid>
}
