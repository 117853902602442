//
//  appstorelinks.js
//  AppStoreLinks
//
//  Created by Tevon Wallace on 11/12/20.
//  Copyright © 2020 Tevon Wallace. All rights reserved.
//

import React from 'react'
import { Grid } from '@material-ui/core';
import './appstorelinks.scss'
import { toDarkModeName } from '../../../utils/utils';

export const AppStoreLinks = (props) => {
    const xsSize = window.innerWidth < 600 ? 6 : window.innerWidth < 800 ? 12 : 6

    return <div className={toDarkModeName(props.mode, "appStore")}>
        <Grid container direction={"row"} justify={"space-evenly"}>
            <Grid item xs={12} key={"appStoreLink"}>
                <div>
                    <div className={toDarkModeName(props.mode, "appStoreTitle")}>{props.stores[0].title}</div>
                    <div className={toDarkModeName(props.mode, "appStoreDescription")}>{props.stores[0].description}</div>

                    <Grid container direction={"row"} justify={"space-evenly"} className={"appStoreLinks"} style={{ marginTop: 5, marginBottom: 10 }}>
                        {props.stores.map((store, index) => (
                            <Grid item xs={xsSize} sm={props.stores.length === 2 ? 3 : 6}>
                                <a href={store.link} target={"_blank"} rel="noopener noreferrer"><div className={"appStoreItem"}>{store.title} {store.downloadLinkTitle}</div></a>
                            </Grid>
                        ))}

                        {props.stores[0].additionalLink ?
                            <Grid item xs={xsSize} sm={props.stores.length === 2 ? 3 : 6}>
                                <a href={props.support.link} target={null} rel="noopener noreferrer"><div className={"appStoreItem"}>{props.support.title}</div></a>
                            </Grid> : null}
                    </Grid>
                </div>
            </Grid>

            {/* {props.stores.map((store, index) => (
                <Grid item xs={12} key={"appStoreLink-" + index}>
                    <div>
                        <div className={toDarkModeName(props.mode, "appStoreTitle")}>{store.title}</div>
                        <div className={toDarkModeName(props.mode, "appStoreDescription")}>{store.description}</div>

                        <Grid container direction={"row"} justify={"space-evenly"} className={"appStoreLinks"} style={{ marginTop: 5, marginBottom: 10 }}>
                            <Grid item xs={xsSize} sm={6}>
                                <a href={store.link} target={"_blank"} rel="noopener noreferrer"><div className={"appStoreItem"}>{store.downloadLinkTitle}</div></a>
                            </Grid>
                            {store.additionalLink ?
                                <Grid item xs={xsSize} sm={6}>
                                    <a href={props.support.link} target={null} rel="noopener noreferrer"><div className={"appStoreItem"}>{props.support.title}</div></a>
                                </Grid> : null}
                        </Grid>
                    </div>
                </Grid>
            ))} */}
        </Grid>
    </div>
}