//
//  projectsection.js
//  ProjectSection
//
//  Created by Tevon Wallace on 19/10/19.
//  Copyright © 2020 Tevon Wallace. All rights reserved.
//

import React from 'react'
import { Grid } from '@material-ui/core';
import rightArrow from '../../../assets/images/right_arrow/rightarrow.png';
import './projectitem.scss'
import { toDarkModeName } from '../../../utils/utils';
import { StyleRoot } from 'radium';

export const ProjectSection = (props) => {
    return <StyleRoot>
        <div
            className={"projectSection"}
            style={props.style}>
            <a href={props.href}>
                <Grid container direction={"column"} className={toDarkModeName(props.mode, "projectItem")}>
                    <Grid item xs={12}>
                        <div className={toDarkModeName(props.mode, "projectItemName")}>{props.project.title}</div>
                        <div className={toDarkModeName(props.mode, "projectItemDescription")}>{props.project.description ? props.project.description : null}</div>
                        <div className={"appStoreLink"}>{props.project.links ?
                            <Grid container direction={"row"} justify={"space-evenly"}>
                                {props.project.links.map((link, index) => (
                                    <Grid key={'projectSection' + index} item xs={12} sm={4} style={{ marginTop: 5, marginBottom: 5 }}>
                                        <a href={link.link} target={"_blank"} rel="noopener noreferrer">{link.title} <img className={"rightArrow"} src={rightArrow} alt={""} /></a>
                                    </Grid>
                                ))}
                            </Grid> :
                            props.project.comingSoon ? <div className={"comingSoon"}>Coming Soon</div> : <div style={{ height: 21 }}>Private</div>}
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 20 }}>
                        <img src={props.project.image} className={"projectImage"} alt={"img"}></img>
                    </Grid>
                    {/* {props.project.websiteLink ?
                    <Grid item xs={12}>
                        <div className={toDarkModeName(props.mode, "projectClickToViewDetails")}>{props.project.isMusicVideo ? "Click to watch the music video" : "Click to view details"}</div>
                    </Grid> : null} */}
                </Grid>
            </a>
        </div>
    </StyleRoot>
}
