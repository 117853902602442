//
//  apps.js
//  Apps
//
//  Created by Tevon Wallace on 03/09/21.
//  Copyright © 2020 Tevon Wallace. All rights reserved.
//

import { getProjects } from "../../projects/projectoptions"
import { AppPrivacyPolicy } from "../privacy/privacyOptions"
import { getVisualFileEditorOptions } from "../app_options/visual_file_editor/visualfileeditoroptions"
import { getRestClientOptions } from "../app_options/rest_api_client/restapiclientoptions"
import { getRydeSafeOptions } from '../app_options/ryde_safe/rydesafeoptions'
import { get15Sec1AnsOptions } from '../app_options/15_sec_1_ans/15sec1ansoptions'
import visualFileEditorRoundedImage from '../../../assets/images/projects/visualfileeditor-rounded.png'
import restAPIClientRoundedImage from '../../../assets/images/projects/restapiclient-rounded.png'
import rydeSafeRoundedImage from '../../../assets/images/projects/rydesafe-rounded.png'
import fifteenSec1AnsRoundedImage from '../../../assets/images/projects/15sec1ans-rounded.png'
import {
    appIndexConstants, pages, restAPIClientPageSupportForm, visualFileEditorPageSupportForm
    , rydeSafePageSupportForm, fifteenSec1AnsSupportForm
} from "../../../constants/constants"

export const getApps = (mode) => {
    const projects = getProjects(mode)

    return [
        {
            topComponent: {
                image: visualFileEditorRoundedImage,
                title: "Visual File Editor",
                supportLink: pages[4],
                supportTitle: "Support",
                link: projects[appIndexConstants.visualFileEditor].links[0],
                links: projects[appIndexConstants.visualFileEditor].links,
                privacy: AppPrivacyPolicy("Visual File Editor"),
                mode: mode,
                additionalDescriptions: getVisualFileEditorOptions(mode).additionalDescriptions
            },
            support: {
                roundedImage: visualFileEditorRoundedImage,
                title: "Visual File Editor - Support",
                supportLink: pages[3],
                supportTitle: projects[appIndexConstants.visualFileEditor].title,
                link: projects[appIndexConstants.visualFileEditor].links[0],
                additionalDescriptions: getVisualFileEditorOptions(mode).additionalDescriptions,
                mode: mode,
                links: projects[appIndexConstants.visualFileEditor].links,
                privacy: AppPrivacyPolicy("Visual File Editor"),
            },
            iPhoneImages: getVisualFileEditorOptions(mode).iPhoneImages,
            iPadImages: getVisualFileEditorOptions(mode).iPadImages,
            supportFormPage: visualFileEditorPageSupportForm
        },
        {
            topComponent: {
                image: restAPIClientRoundedImage,
                title: "REST API Client",
                supportLink: pages[7],
                supportTitle: "Support",
                link: projects[appIndexConstants.restAPIClient].links[0],
                links: projects[appIndexConstants.restAPIClient].links,
                privacy: AppPrivacyPolicy("REST API Client"),
                mode: mode,
                additionalDescriptions: getRestClientOptions(mode).additionalDescriptions
            },
            support: {
                roundedImage: restAPIClientRoundedImage,
                title: "REST API Client - Support",
                supportLink: pages[6],
                supportTitle: projects[appIndexConstants.restAPIClient].title,
                link: projects[appIndexConstants.restAPIClient].links[0],
                additionalDescriptions: getRestClientOptions(mode).additionalDescriptions,
                mode: mode,
                links: projects[appIndexConstants.restAPIClient].links,
                privacy: AppPrivacyPolicy("REST API Client"),
            },
            iPhoneImages: getRestClientOptions(mode).iPhoneImages,
            iPadImages: getRestClientOptions(mode).iPadImages,
            supportFormPage: restAPIClientPageSupportForm
        },
        {
            topComponent: {
                image: rydeSafeRoundedImage,
                title: "Ryde Safe",
                supportLink: pages[10],
                supportTitle: "Support",
                link: projects[appIndexConstants.rydeSafe].links[0],
                links: projects[appIndexConstants.rydeSafe].links,
                privacy: AppPrivacyPolicy("Ryde Safe"),
                mode: mode,
                additionalDescriptions: getRydeSafeOptions(mode).additionalDescriptions
            },
            support: {
                roundedImage: rydeSafeRoundedImage,
                title: "Ryde Safe - Support",
                supportLink: pages[9],
                supportTitle: projects[appIndexConstants.rydeSafe].title,
                link: projects[appIndexConstants.rydeSafe].links[0],
                additionalDescriptions: getRydeSafeOptions(mode).additionalDescriptions,
                mode: mode,
                links: projects[appIndexConstants.rydeSafe].links,
                privacy: AppPrivacyPolicy("Ryde Safe"),
            },
            iPhoneImages: getRydeSafeOptions(mode).iPhoneImages,
            iPadImages: getRydeSafeOptions(mode).iPadImages,
            supportFormPage: rydeSafePageSupportForm
        },
        {
            topComponent: {
                image: fifteenSec1AnsRoundedImage,
                title: "15 Sec 1 Ans",
                supportLink: pages[14],
                supportTitle: "Support",
                link: projects[appIndexConstants.fifteenSec1Ans].links[0],
                links: projects[appIndexConstants.fifteenSec1Ans].links,
                privacy: AppPrivacyPolicy("15 Sec 1 Ans"),
                mode: mode,
                additionalDescriptions: get15Sec1AnsOptions(mode).additionalDescriptions
            },
            support: {
                roundedImage: fifteenSec1AnsRoundedImage,
                title: "15 Sec 1 Ans - Support",
                supportLink: pages[13],
                supportTitle: projects[appIndexConstants.fifteenSec1Ans].title,
                link: projects[appIndexConstants.fifteenSec1Ans].links[0],
                additionalDescriptions: get15Sec1AnsOptions(mode).additionalDescriptions,
                mode: mode,
                links: projects[appIndexConstants.fifteenSec1Ans].links,
                privacy: AppPrivacyPolicy("15 Sec 1 Ans"),
            },
            iPhoneImages: get15Sec1AnsOptions(mode).iPhoneImages,
            iPadImages: get15Sec1AnsOptions(mode).iPadImages,
            supportFormPage: fifteenSec1AnsSupportForm
        }
    ]
}