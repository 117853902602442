//
//  projects.js
//  Projects
//
//  Created by Tevon Wallace on 12/8/19.
//  Copyright © 2020 Tevon Wallace. All rights reserved.
//

import React from 'react'
import { Grid } from '@material-ui/core'
import './projects.scss'
import { ProjectItem } from './projects/projectitem';
import { getProjects } from './projectoptions'
import { toDarkModeName } from '../../utils/utils';
// import { fadeIn } from 'react-animations'
// import Radium from 'radium';

/*const animationStyles = {
    fadeIn: {
        animation: 'x 1s',
        animationName: Radium.keyframes(fadeIn, 'fadeIn')
    }
}*/

class Projects extends React.Component {
    render() {
        const { mode } = this.props

        return (
            <div className={toDarkModeName(mode, "entireProjectSection")}>
                <Grid container>
                    <Grid item xs={12}>
                        <div className={toDarkModeName(mode, "nameAndDescription")}>
                            <div className={toDarkModeName(mode, "name")}>Products</div>
                            <div className={toDarkModeName(mode, "description")}>View more details below.</div>
                        </div>
                    </Grid>
                </Grid>

                <Grid container style={{ marginTop: 20, marginBottom: 20 }} justify={"space-evenly"} direction={'row'}>
                    {getProjects(mode).map((project, index) => (
                        <Grid item key={"projects" + index} xs={12} sm={window.innerWidth < 1000 ? 5 : window.innerWidth > 1400 ? 3 : 6} className={toDarkModeName(mode, "subProjectSection")}>
                            <ProjectItem
                                project={project}
                                mode={mode}
                               /* style={animationStyles.fadeIn} */ s />
                        </Grid>
                    ))}
                </Grid>
            </div>
        )
    }
}

export default Projects
