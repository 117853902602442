//
//  input.js
//  Input
//
//  Created by Tevon Wallace on 07/08/21.
//  Copyright © 2021 Tevon Wallace. All rights reserved.
//

import React from 'react'
import { Grid, InputLabel, MenuItem, Select, TextField, makeStyles } from '@material-ui/core';
import ReusableButton from '../button';

const useStyles = makeStyles((theme) => ({
    input: {
        color: (props) => props.mode === 'dark' ? 'white' : 'black',
        "&:before": {
            borderColor: 'grey'
        }
    },
    label: {
        color: (props) => props.mode === 'dark' ? 'grey' : 'black',
        marginTop: 17,
        "&:before": {
            borderColor: 'grey'
        }
    },
    darkModeInput: {
        color: 'grey'
    },
    select: {
        color: (props) => props.mode === 'dark' ? 'white' : 'black',
        textAlign: 'right',
        "&:before": {
            borderColor: 'grey'
        }
    },
    selectIcon: {
        fill: (props) => props.mode === 'dark' ? 'white' : 'black'
    }
}));

export const Input = (props) => {
    const classes = useStyles(props)
    const { field, width, handleChange, mode } = props

    return (
        <div style={{ width: '100%' }}>
            {field.elementConfig.type === 'text' ?
                <TextField
                    id={field.id}
                    label={field.label}
                    value={field.value}
                    style={{ width: width }}
                    error={field.errorMessage}
                    helperText={field.errorMessage}
                    InputProps={{
                        className: classes.input
                    }}
                    InputLabelProps={{
                        className: classes.darkModeInput
                    }}
                    onChange={handleChange(field.formElement)} />
                : field.elementConfig.type === 'select' ?
                    <Grid container direction={"row"} justify={"space-between"}>
                        <Grid item xs={2} sm={3}>
                            <InputLabel id={"selectFieldLabel-" + field.label} className={classes.label}>{field.label}</InputLabel>
                        </Grid>

                        <Grid item xs={6} sm={6}>
                            <Select
                                labelId={field.label}
                                id={field.id}
                                value={field.value}
                                error={field.errorMessage}
                                className={classes.select}
                                inputProps={{
                                    classes: {
                                        icon: classes.selectIcon
                                    }
                                }}
                                style={{ width: width }}
                                onChange={handleChange(field.formElement)} >
                                {field.elementConfig.options.map((item, index) => (
                                    <MenuItem value={item.value} key={"menuItem-selectField-" + field.label + "-" + index}>{item.title}</MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>
                    : field.elementConfig.type === 'button' ?
                        <Grid item xs={12}>
                            <Grid container direction={'row'} justify={'center'} style={{ marginTop: 20 }}>
                                <ReusableButton
                                    label={field.label.toUpperCase()}
                                    disabled={field.isDisabled}
                                    isLoading={field.isLoading}
                                    mode={mode}
                                    backgroundColor={mode === 'dark' ? 'FFFFFF' : 'FAFAFA'}
                                    style={!field.isDisabled && mode === 'dark' ? { backgroundColor: 'white' } : null}
                                    onClick={field.buttonWasPressed} />
                            </Grid>
                        </Grid> : null}
        </div>
    )
}
