import {
    middleWareApiUrl, submitAPIKey, visualFileEditorSupportFormAPIKey, submitContactFormUrl, visualFileEditorSupportFormUrl,
    restAPIClientSupportFormAPIKey, restAPIClientSupportFormUrl, visualFileEditorPageSupportForm, restAPIClientPageSupportForm,
    rydeSafePageSupportForm, rydeSafeSupportFormAPIKey, rydeSafeSupportFormUrl, fifteenSec1AnsSupportForm, fifteenSec1AnsSupportFormUrl, fifteenSec1AnsFormAPIKey
} from "../constants/constants"

class HttpClient {
    async submitContactForm(body) {
        let options = {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'apikey': submitAPIKey
            },
            body: JSON.stringify(body)
        }

        return this._fetch(options, submitContactFormUrl)
    }

    async submitAppSupportForm(body) {
        if (body.page === visualFileEditorPageSupportForm) {
            return await this.submitAppFormRequest(visualFileEditorSupportFormAPIKey, visualFileEditorSupportFormUrl, body)
        }
        else if (body.page === restAPIClientPageSupportForm) {
            return await this.submitAppFormRequest(restAPIClientSupportFormAPIKey, restAPIClientSupportFormUrl, body)
        }
        else if (body.page === rydeSafePageSupportForm) {
            return await this.submitAppFormRequest(rydeSafeSupportFormAPIKey, rydeSafeSupportFormUrl, body)
        }
        else if (body.page === fifteenSec1AnsSupportForm) {
            return await this.submitAppFormRequest(fifteenSec1AnsFormAPIKey, fifteenSec1AnsSupportFormUrl, body)
        }
        else {
            return Error("error submitting request")
        }
    }

    async submitAppFormRequest(apiKey, url, body) {
        let options = {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'apikey': apiKey
            },
            body: JSON.stringify(body)
        }

        return await this._fetch(options, url)
    }

    async _fetch(options, url) {
        return this.timeout(30000, fetch(middleWareApiUrl + url, options)).then(data => {
            return data
        })
            .catch(_ => {
                throw Error("Error submitting the form")
            })
    }

    timeout(ms, promise) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                reject(new Error('Request timed out'))
            }, ms)
            promise.then(resolve, reject)
        })
    }
}

export default HttpClient
