//
//  httprequests.js
//  HttpRequests
//
//  Created by Tevon Wallace on 14/8/19.
//  Copyright © 2020 Tevon Wallace. All rights reserved.
//

import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getDatabase } from 'firebase/database'
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'

const config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
}

class Firebase {
    constructor() {
        const app = initializeApp(config)

        getAnalytics(app)
        this.database = getDatabase(app)

        initializeAppCheck(app, {
            provider: new ReCaptchaV3Provider(process.env.REACT_APP_GOOGLE_CAPTCHA_KEY),
            isTokenAutoRefreshEnabled: true
        })
    }
}

export default Firebase
